import { Box, Card, EFont, EPixel, Font, SvgIcon, useMediaQueries } from "@hkexpressairwayslimited/ui";
import { Stack, SvgIconProps } from "@mui/material";
import { ReactElement, SVGProps } from "react";

export type TitleIconProps = {
  icon?: ReactElement<SVGProps<SVGSVGElement>, "svg">;
  children: string;
} & SvgIconProps;

export default function TitleIcon({ icon, children }: TitleIconProps) {
  const { isMobile } = useMediaQueries();
  return (
    <>
      <Stack spacing={2} direction='row' alignItems='center'>
        {icon && (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              borderRadius: "50%",
              backgroundColor: "neutral.white",
              width: "64px",
              height: "64px",
              lineHeight: "64px",
              verticalAlign: "middle",
              fontSize: EPixel.px32,
            }}
          >
            <SvgIcon color='primary'>{icon}</SvgIcon>
          </Stack>
        )}
        <Font variant={isMobile ? EFont.h3 : EFont.h1}>{children}</Font>
      </Stack>
    </>
  );
}

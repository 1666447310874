import { clearAuthTokens } from "@hkexpressairwayslimited/ui";
import {
  BackendResponse,
  addQueryParam,
  getAccessTokenHeader,
  getApi,
  memberAuthEndPoints,
  postApi,
  windowOpenNewTab,
} from "api";
import { envConfig } from "env";
import { I18nInstance } from "modules/common/common/utils/i18n-utils";
import store from "store";
import { setCxProfile, setMlcTokenProfile } from "../member/actions";
import { clearFallback, clearMlcAccessToken, setFallback } from "./utils/authUtil";

export async function requestLogout(): Promise<BackendResponse> {
  return await postApi(`${memberAuthEndPoints.logout}`);
}

export async function signInCx(callbackUrl: string = "/", newWindow: boolean = false): Promise<BackendResponse> {
  clearAuthTokens();
  const url = `${envConfig.apiDomain}/auth/v1/oauth/method/mlc?lang=${I18nInstance.getI18nInstance().language}`;
  return signInByFollowRedirectionAndSetCallbackUrl(url, callbackUrl, newWindow);
}

export async function bindCx(callbackUrl: string = "/member/link-cx"): Promise<BackendResponse> {
  const url = `${envConfig.apiDomain}/auth/v1/oauth/method/mlc/bind?lang=${I18nInstance.getI18nInstance().language}`;
  return signInByFollowRedirectionAndSetCallbackUrl(url, callbackUrl, false);
}
export async function signInApple(callbackUrl: string = "/"): Promise<BackendResponse> {
  clearAuthTokens();
  const url = `${envConfig.apiDomain}/auth/v1/oauth/method/apple?lang=${I18nInstance.getI18nInstance().language}`;
  return signInByFollowRedirectionAndSetCallbackUrl(url, callbackUrl, false);
}
async function signInByFollowRedirectionAndSetCallbackUrl(url: string, callbackUrl: string, newWindow: boolean) {
  if (callbackUrl) {
    setFallback(callbackUrl);
  } else {
    clearFallback();
  }
  const urlParams = new URLSearchParams(window.location.search);
  const device = urlParams.get("device") || "web";
  const newUrl = addQueryParam(url, "device", device);
  const accessTokenHeader = getAccessTokenHeader();
  const response = await getApi(newUrl, accessTokenHeader, undefined, {
    redirect: "manual",
  });
  if (response.redirect_url) {
    if (newWindow) {
      windowOpenNewTab(response.redirect_url);
    } else {
      window.location.href = response.redirect_url;
    }
  } else {
    throw Error("failure");
  }
}

enum IdentityProvider {
  MLC = "MLC",
  APPLE = "APPLE",
}

export async function unlinkIdp(idp: IdentityProvider): Promise<BackendResponse> {
  return await postApi(`${memberAuthEndPoints.unlinkIdp}`, { idp });
}

export async function unlinkCx() {
  await unlinkIdp(IdentityProvider.MLC);
  clearMlcAccessToken();
  store.dispatch(setMlcTokenProfile(undefined));
  store.dispatch(setCxProfile(undefined));
}

export async function unlinkApple() {
  await unlinkIdp(IdentityProvider.APPLE);
}

import { isEmpty } from "lodash";
import { apiBrowserTraceIdLogger, apiErrorLogger, apiInfoLogger, apiWarnLogger } from "loggers";
import { hCaptchaInstance } from "modules/common/common/utils/hcaptcha-utils";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { getAccessToken } from "modules/features/auth/utils/authUtil";
import { pushApiErrorCode } from "modules/global/general";
import { RouterInstance } from "router/router-utils";
import store from "store";
import { selectAgentToken, selectEntered } from "store/sessionStorage/slices/b2bSlice";
import { HCAPTCHA_ERROR, NETWORK_ERROR } from "utils/errors";
import { envConfig } from "./env";

export const endpoints = {
  adminHost: envConfig.endpointAdminHost,
  manageDomain: envConfig.manageDomain,
  apiDomain: envConfig.apiDomain,
  endpointPaymentHost: envConfig.endpointPaymentHost,
  order: `${envConfig.endpointOrderHost}/v1/order`,
  createPayment: `${envConfig.endpointPaymentHost}/external/v1/payment/create-payment`,
  mlc: "https://payment-t1.ete.cathaypacific.com/payment-link/test/mlc",
  cxMemberProfile: `${envConfig.endpointMemberHost}/v1/member-info`,
  memberAndMilesInfo: `${envConfig.endpointMemberHost}/v1/member-and-miles-info`,
  lowFare: `${envConfig.endPointFlightBookingQueryHost}/public/v1/low-fare/availability`,
  getFlightAvailability: `${envConfig.endPointFlightBookingQueryHost}/public/v1/availability/search`,
  ssrBooking: `${envConfig.endPointFlightBookingQueryHost}/v1/ssr-booking`,
  flightRouteMapping: `${envConfig.endpointAdminHost}/public/v1/flight-route-mapping`,
  createNskToken: `${envConfig.endPointFlightBookingQueryHost}/public/v1/nsk/token`,
  getTravelCompanion: `${envConfig.endPointAuthHost}/v1/auth/user/travel-companion`,
  flightSelectCreateOrder: `${envConfig.endPointFlightBookingManagementHost}/v1/trip`,
  getOrderStatus: `${envConfig.endpointOrderHost}/v1/order`,
  orderValidation: `${envConfig.endpointOrderHost}/v1/order/validation`,
  currencyRate: `${envConfig.endPointFlightBookingQueryHost}/public/v1/currency-rate`,
  voucher: envConfig.endpointVoucher,
  paymentDoneConfirmRetrieveBooking: `${envConfig.endpointOrderHost}/v1/booking/retrieve`,
  promotionList: `${envConfig.endpointAdminHost}/public/v1/promotion/`,
  paxInfoTravelDOC: `${envConfig.endpointAdminHost}/public/v1/pax-info`,
};

export const b2bEndPoints = {
  b2bProfile: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/profile`,
  b2bAgentToken: `${envConfig.endPointFlightBookingQueryHost}/public/v1/b2b/nsk/token`,
  b2bAgentTokenLogout: `${envConfig.endPointFlightBookingQueryHost}/public/v1/nsk/token`,
  orderValidationB2B: `${envConfig.endpointOrderHost}/v1/b2b/order/validation`,
  flightSelectCreateOrderB2B: `${envConfig.endPointFlightBookingManagementHost}/v1/b2b/trip`,
  ssrBookingB2B: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/ssr-booking`,
  getFlightAvailabilityB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/B2B/availability/search`,
  lowFareB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/B2B/low-fare/availability`,
  orderB2B: `${envConfig.endpointOrderHost}/v1/b2b/order`,
  orderOnHoldB2B: `${envConfig.endpointOrderHost}/v1/b2b/booking/onhold`,
  orderReleaseB2B: `${envConfig.endpointOrderHost}/v1/b2b/booking/release`,
  reportGenerationRequestB2B: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/sales-report/generate`,
  reportQueryStatusB2B: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/sales-report/query`,
  reportDownloadB2B: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/sales-report/download`,
  retrieveBookingByPNRB2B: `${envConfig.endpointOrderHost}/v1/b2b/booking/retrieve`,
  getBookingSSRB2B: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/mmb/ssr-booking`,
  orderValidateAddAncilieoB2B: `${envConfig.endpointOrderHost}/v1/b2b/mmb/order/validation`,
  orderCreateAddAncilieoB2B: `${envConfig.endpointOrderHost}/v1/b2b/mmb/order`,
  changeFlightOrderValidationB2B: `${envConfig.endpointOrderHost}/v1/b2b/mmb/change-flight/order/validation`,
  changeFlightB2B: `${envConfig.endPointFlightBookingManagementHost}/v1/b2b/change-flight`,
  mmbFlightSearchB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/b2b/mmb/availability/search/flight-change`,
  mmbLowFareB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/b2b/mmb/low-fare/availability/flight-change`,
  changeFlightSSRB2B: `${envConfig.endPointFlightBookingQueryHost}/v1/b2b/mmb/change-flight/ssr-booking`,
  changeFlightOrderCreationB2B: `${envConfig.endpointOrderHost}/v1/b2b/mmb/change-flight/order`,
  resendEmailB2B: `${envConfig.endpointOrderHost}/v1/b2b/itinerary/resend/email`,
  resendSmsB2B: `${envConfig.endpointOrderHost}/v1/b2b/itinerary/resend/sms`,
  changePassengerB2B: `${envConfig.endPointFlightBookingManagementHost}/v1/b2b/mmb/booking/update-passenger-detail`,
  addRemarksB2B: `${envConfig.endPointFlightBookingManagementHost}/v1/b2b/mmb/booking/update-booking-remark`,
  unsettlePaymentOrderValidationB2B: `${envConfig.endpointOrderHost}/v1/b2b/mmb/order/unsettle-payment/validation`,
  unsettlePaymentOrderCreationB2B: `${envConfig.endpointOrderHost}/v1/b2b/mmb/unsettle-payment/order`,
  changePasswordB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/b2b/nsk/change-password`,
  forgetPasswordSendEmailB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/b2b/nsk/forgot-password`,
  forgetPasswordConfirmB2B: `${envConfig.endPointFlightBookingQueryHost}/public/v1/b2b/nsk/forgot-password/confirmation`,
};

export const mmbEndPoints = {
  retrieveBookingByPNR: `${envConfig.endpointOrderHost}/v1/booking/retrieve`,
  retrieveBookingByMemberID: `${envConfig.endpointOrderHost}/v1/booking/retrieve/uo-member`,
  resendEmail: `${envConfig.endpointOrderHost}/v1/itinerary/resend/email`,
  resendSMS: `${envConfig.endpointOrderHost}/v1/itinerary/resend/sms`,
  changePassenger: `${envConfig.endPointFlightBookingManagementHost}/v1/mmb/booking/update-passenger-detail`,
  getBookingSSR: `${envConfig.endPointFlightBookingQueryHost}/v1/mmb/ssr-booking`,
  orderValidateAddAncilieo: `${envConfig.endpointOrderHost}/v1/mmb/order/validation`,
  orderCreateAddAncilieo: `${envConfig.endpointOrderHost}/v1/mmb/order`,
  changeFlight: `${envConfig.endPointFlightBookingManagementHost}/v1/change-flight`,
  mmbLowFare: `${envConfig.endPointFlightBookingQueryHost}/public/v1/mmb/low-fare/availability/flight-change`,
  mmbFlightSearch: `${envConfig.endPointFlightBookingQueryHost}/public/v1/mmb/availability/search/flight-change`,
  retrieveBoardingPass: `${envConfig.endPointFlightBookingQueryHost}/v1/booking/boardingpasses/retrieve`,
  changeFlightSSR: `${envConfig.endPointFlightBookingQueryHost}/v1/mmb/change-flight/ssr-booking`,
  changeFlightOrderValidation: `${envConfig.endpointOrderHost}/v1/mmb/change-flight/order/validation`,
  changeFlightOrderCreation: `${envConfig.endpointOrderHost}/v1/mmb/change-flight/order`,
  retrieveBookingUnderAgent: `${envConfig.endpointOrderHost}/public/v1/booking/retrieve/agent`,
  unsettlePaymentOrderValidation: `${envConfig.endpointOrderHost}/v1/mmb/order/unsettle-payment/validation`,
  unsettlePaymentOrderCreation: `${envConfig.endpointOrderHost}/v1/mmb/unsettle-payment/order`,
};

export const memberAuthEndPoints = {
  setPassword: `${envConfig.endPointAuthHost}/v1/auth/user/password`,
  refreshToken: `${envConfig.endPointAuthHost}/v1/auth/refresh-token`,
  signUp: `${envConfig.endPointAuthHost}/v1/auth/signup`,
  confirmSignUp: `${envConfig.endPointAuthHost}/v1/auth/signup/confirmation`,
  signIn: `${envConfig.endPointAuthHost}/v1/auth/token`,
  logout: `${envConfig.endPointAuthHost}/v1/auth/logout`,
  forgotPassword: `${envConfig.endPointAuthHost}/v1/auth/user/forgot-password`,
  getProfile: `${envConfig.endPointAuthHost}/v1/auth/profile`,
  profileConfirm: `${envConfig.endPointAuthHost}/v1/auth/user/verify-profile/confirmation`,
  resendOTP: `${envConfig.endPointAuthHost}/v1/auth/signup/resend-otp`,
  updateEmail: `${envConfig.endPointAuthHost}/v1/auth/profile/email`,
  updateMobile: `${envConfig.endPointAuthHost}/v1/auth/profile/phone`,
  verifyUnverifiedEmailConfirm: `${envConfig.endPointAuthHost}/v1/auth/user/verify-email`,
  verifyUnverifiedMobileConfirm: `${envConfig.endPointAuthHost}/v1/auth/user/verify-phone`,
  verifyEmailConfirm: `${envConfig.endPointAuthHost}/v1/auth/user/verify-email/confirmation`,
  verifyMobileConfirm: `${envConfig.endPointAuthHost}/v1/auth/user/verify-phone/confirmation`,
  checkPhone: `${envConfig.endPointAuthHost}/v1/auth/check-phone-availability`,
  checkEmail: `${envConfig.endPointAuthHost}/v1/auth/check-email-availability`,
  completeProfile: `${envConfig.endPointAuthHost}/v1/auth/complete-profile`,
  incompleteProfile: `${envConfig.endPointAuthHost}/v1/auth/get-incomplete-profile`,
  unlinkIdp: `${envConfig.endPointAuthHost}/v1/auth/user/external-idp/unlink`,
};

export const olciEndPonits = {
  retrieveBoardingPass: `${envConfig.endPointFlightBookingQueryHost}/v1/booking/boardingpasses/retrieve/`,
  getBookingSSR: `${envConfig.endPointFlightBookingQueryHost}/v1/olci/ssr-booking`,
  checkIn: `${envConfig.endPointFlightBookingManagementHost}/v1/olci/journey`,
  checkPassengers: `${envConfig.endPointFlightBookingManagementHost}/v1/olci/booking/update-passenger-detail`,
  orderValidation: `${envConfig.endpointOrderHost}/v1/olci/order/validation`,
  order: `${envConfig.endpointOrderHost}/v1/olci/order`,
};

export type ApiRequestInit = Omit<RequestInit, "credentials"> & {
  credentials?: boolean;
  errorCodeWhitelist?: string[];
  showBackDrop?: boolean;
  captcha?: boolean;
  customErrorMessage?: string;
};

export type BackendResponse<ResponseBody extends Record<string, {}> = any> = ResponseBody & {
  metadata: {
    app_version: string;
    status_code: number;
    trace_id: string;
  };
  error_code: string;
  error_message: string;
  error_detail: string;
};

export const fetchAPI = async <ResponseBodyType extends Record<string, {}> = any>(
  url: string,
  options: ApiRequestInit = {},
  customErrorHandling: boolean = false,
  followRedirect: boolean = false
) => {
  const {
    credentials = false,
    redirect = "follow",
    errorCodeWhitelist = [],
    showBackDrop = true,
    captcha = false,
    headers = {},
    customErrorMessage = "",
    ...restOptions
  } = options;
  const newOptions: RequestInit = {
    mode: "cors",
    redirect: redirect ? redirect : "follow",
    credentials: credentials ? "include" : undefined, // respect set-cookie header in response
    ...restOptions,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };
  if (typeof window !== "undefined") {
    showBackDrop && store.dispatch(showLoadingBackdrop());
  }
  if (captcha && hCaptchaInstance.getConfig().isEnable) {
    // only if hCaptcha is enabled by both client and server
    const captchaResult = await hCaptchaInstance
      .execute()
      .then((result) => {
        if (result) {
          return result;
        }
        throw HCAPTCHA_ERROR;
      })
      .catch((err) => {
        if (typeof window !== "undefined") {
          store.dispatch(pushApiErrorCode(HCAPTCHA_ERROR));
        }
        apiErrorLogger(err);
        throw err;
      });

    newOptions.headers = { ...newOptions.headers, "X-HCAPTCHA-RESPONSE": captchaResult.response };
  }
  apiInfoLogger(url, newOptions);
  const response = await fetch(url, newOptions)
    .then((res) => {
      if (
        res.redirected &&
        (followRedirect || (!res.url.startsWith(envConfig.apiDomain) && !res.url.startsWith(envConfig.bookingHost)))
      ) {
        // Queue-it
        apiInfoLogger("Redirecting to", res.url);
        if (typeof window !== "undefined") {
          RouterInstance.push(res.url);
        }
      }
      return res;
    })
    .catch((err) => {
      if (typeof window !== "undefined") {
        store.dispatch(pushApiErrorCode(NETWORK_ERROR));
      }
      apiErrorLogger(err);
      throw err;
    })
    .finally(() => {
      if (typeof window !== "undefined") {
        showBackDrop && store.dispatch(closeLoadingBackdrop());
      }
    });

  apiInfoLogger(response);

  const result: BackendResponse<ResponseBodyType> = await response.json().catch((err) => {
    apiWarnLogger(err, response);
    throw response;
  });

  if (result.error_code) {
    if (
      result.metadata &&
      !errorCodeWhitelist.includes(result.error_code) &&
      !errorCodeWhitelist.includes(result.error_message)
    ) {
      const apiErrorMsg = `${result.error_code}${apiBrowserTraceIdLogger.enabled ? `[trace_id:${result.metadata.trace_id}]` : ""}`;
      if (!customErrorHandling) {
        if (typeof window !== "undefined") {
          store.dispatch(pushApiErrorCode(apiErrorMsg));
        }
      }
      apiBrowserTraceIdLogger(apiErrorMsg);
      apiErrorLogger(result);
    } else if (errorCodeWhitelist.includes(result.error_message)) {
      if (customErrorMessage) {
        if (typeof window !== "undefined") {
          store.dispatch(pushApiErrorCode(customErrorMessage));
        }
      }
      apiErrorLogger(result);
    } else {
      apiWarnLogger(result);
    }
    throw result;
  }
  return result;
};

export const getAccessTokenHeader = (): HeadersInit => {
  const state = store.getState();
  const enteredB2b = selectEntered(state);
  const agentToken = selectAgentToken(state);
  console.log("enteredB2b", enteredB2b);
  console.log("agentToken", agentToken);

  // In B2B flow, we need to check if the agent token is still valid and append it to the header
  if (enteredB2b) {
    if (agentToken) {
      return {
        nsk_token: agentToken.token,
      };
    }
  } else {
    // In normal flow, we need to check if the access token is still valid and append it to the header
    const access_token = getAccessToken();

    if (access_token) {
      return { Authorization: `Bearer ${access_token}` };
    }
  }
  return {};
};
export const getApi = async <ResponseBodyType extends Record<string, any> = any>(
  url: string,
  headers: HeadersInit = {},
  data: { [key: string]: any } = {},
  options: ApiRequestInit = {},
  customErrorHandling: boolean = false
) => {
  const requestOptions: ApiRequestInit = {
    method: "GET",
    headers,
  };
  const accessTokenHeader = getAccessTokenHeader();
  let newUrl = url;
  for (const [key, value] of Object.entries(data)) {
    newUrl = addQueryParam(newUrl, key, value);
  }
  const opt = {
    ...requestOptions,
    headers: { ...requestOptions.headers, ...accessTokenHeader },
    ...options,
  };
  const response = await fetchAPI<ResponseBodyType>(newUrl, opt, customErrorHandling);
  return response;
};
export const postApi = async <ResponseBodyType extends Record<string, any> = any>(
  url: string,
  body: object = {},
  headers: HeadersInit = {},
  options: ApiRequestInit = {},
  customErrorHandling: boolean = false,
  followRedirect: boolean = true
) => {
  const requestOptions: ApiRequestInit = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };
  const accessTokenHeader = getAccessTokenHeader();
  const response = await fetchAPI<ResponseBodyType>(
    url,
    {
      ...requestOptions,
      headers: { ...requestOptions.headers, ...accessTokenHeader },
      ...options,
    },
    customErrorHandling,
    followRedirect
  );
  return response;
};
export const putApi = async <ResponseBodyType extends Record<string, any> = any>(
  url: string,
  body: object = {},
  headers: HeadersInit = {},
  options: ApiRequestInit = {},
  customErrorHandling: boolean = false
) => {
  const requestOptions: ApiRequestInit = {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  };
  const accessTokenHeader = getAccessTokenHeader();
  const response = await fetchAPI<ResponseBodyType>(
    url,
    {
      ...requestOptions,
      headers: { ...requestOptions.headers, ...accessTokenHeader },
      ...options,
    },
    customErrorHandling
  );
  return response;
};
export const deleteApi = async <ResponseBodyType extends Record<string, any> = any>(
  url: string,
  body: object = {},
  headers: HeadersInit = {},
  options: ApiRequestInit = {},
  customErrorHandling: boolean = false
) => {
  const requestOptions: ApiRequestInit = {
    method: "DELETE",
    headers,
    body: JSON.stringify(body),
  };
  const accessTokenHeader = getAccessTokenHeader();
  const response = await fetchAPI<ResponseBodyType>(
    url,
    {
      ...requestOptions,
      headers: { ...requestOptions.headers, ...accessTokenHeader },
      ...options,
    },
    customErrorHandling
  );
  return response;
};

export const parseJwt = (token: string) => {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return {};
  }
};

export const parseUserProfile = function (token: string): {
  email: string;
  phone: string;
  dob: string;
  givenName: string;
  familyName: string;
  title: string;
} {
  const profile = parseJwt(token);
  const { email, phone_number, birthdate, given_name, family_name } = profile;
  return {
    email,
    phone: phone_number,
    dob: birthdate,
    givenName: given_name,
    familyName: family_name,
    title: profile["custom:title"],
  };
};

export const postMessageToMobile = function (data: object) {
  return window.ReactNativeWebView?.postMessage(JSON.stringify(data));
};

export function parseJSONtoQueryString(jsonObj?: { [key: string]: any }): string {
  if (!jsonObj || isEmpty(jsonObj)) return "";
  let str = "?";
  for (const i in jsonObj) {
    str += `${i}=${jsonObj[i]}&`;
  }
  return str.substring(0, str.length - 1);
}

export function addQueryParam(url: string, key: string, value: string) {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);
  const kvp = url.split("&");
  let i = 0;
  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + "=")) {
      const pair = kvp[i].split("=");
      pair[1] = value;
      kvp[i] = pair.join("=");
      break;
    }
  }
  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join("=");
  }
  const params = kvp.join("&");
  return params;
}

export function windowOpenNewTab(url: string) {
  const newTab = window.open();
  if (newTab) {
    newTab.location.href = url;
  } else {
    window.open(url, "_blank");
  }
}

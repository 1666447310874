import {
  ArItem,
  EPaxType,
  IBEPax,
  Journey,
  ManageMyBookingSearchingDetail,
  MMBPax,
  Pax,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { createSlice } from "@reduxjs/toolkit";
import { AnalyticPaymentDetail } from "analytic";
import { PaymentMetaData, PaymentOption, PaymentPageDetail } from "lib/features/flight-book/payment/definition";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { cloneDeep, remove } from "lodash";
import { RootState } from "store";
import { EArItemType, EBaggageArCode, SelectableArItem } from "ui/features/flight-booking/add-extras/d";

export type SelectedArItems = {
  tripId?: string;
  journeyKey?: string;
  segmentKey?: string;
  arItems: ArItem[];
  type?: EArItemType;
};
export type TripDetailPageMetaData = {
  updatePassengerResult: boolean;
  baggageSelected: {
    [K: string]: SelectableArItem[];
  };
  selectedArItems: SelectedArItems[];
};
export type ChangeFlightAddExtrasPageMetaData = {
  baggageSelected: {
    [paxId: string]: SelectableArItem[];
  };
  confirmedItems: Partial<Record<EArItemType, boolean>>;
  selectedArItems: SelectedArItems[];
};

export type ChangeFlightJourneyDate = {
  journey_key: string;
  fare_availability_key: string;
};

export type Fees = {
  due_balance: number;
  change_flight_fees_total: number;
  spoilage_fees_total: number;
};
export interface ManageMyBookingReducerProps {
  manageMyBookingSearchingDetail?: ManageMyBookingSearchingDetail;
  trips?: ManageMyBookingTripDetail[];
  tripDetailPageMetaData: TripDetailPageMetaData;
  stepperIndex: number;
  // passengerDetail?: MMBPassengersDetail;
  mmbNskTokenExpireNotify: boolean;
  changeFlightTripDetail?: TripDetail & {
    passengers: IBEPax[];
    notTransferSSR: any[];
    fees: Fees;
    changeFlightJourneyDate: {
      first_journey?: ChangeFlightJourneyDate;
      second_journey?: ChangeFlightJourneyDate;
    };
  };
  changeFlightAddExtraMetaData: ChangeFlightAddExtrasPageMetaData;
  paymentMetaData?: PaymentMetaData;
  paymentPageDetail: PaymentPageDetail;
  changeFlightAnalyticPaymentDetail?: AnalyticPaymentDetail;
  mmbAnalyticPaymentDetail?: AnalyticPaymentDetail;
}

const initialState: ManageMyBookingReducerProps = {
  manageMyBookingSearchingDetail: undefined,
  stepperIndex: 1,
  trips: undefined,
  tripDetailPageMetaData: {
    updatePassengerResult: false,
    baggageSelected: {},
    selectedArItems: [],
  },
  mmbNskTokenExpireNotify: false,
  changeFlightTripDetail: undefined,
  changeFlightAddExtraMetaData: {
    baggageSelected: {},
    confirmedItems: {},
    selectedArItems: [],
  },
  paymentMetaData: undefined,
  paymentPageDetail: {},
  changeFlightAnalyticPaymentDetail: undefined,
  mmbAnalyticPaymentDetail: undefined,
};

export const manageMyBookingSlice = createSlice({
  name: "manageMyBooking",
  initialState,
  reducers: {
    resetChangeFlightStep2State: (state) => {
      // state.stepperIndex = 2;
      state.changeFlightTripDetail = initialState.changeFlightTripDetail;
      state.changeFlightAddExtraMetaData = initialState.changeFlightAddExtraMetaData;
    },
    resetChangeFlightStep3State: (state) => {
      // state.stepperIndex = 3;

      if (state.changeFlightTripDetail?.arItems.selected) {
        state.changeFlightTripDetail.arItems.selected = [];
      }
    },
    resetChangeFlightStep4State: (state) => {
      // state.stepperIndex = 4;
      if (state.changeFlightTripDetail?.arItems.selected) {
        state.changeFlightTripDetail.arItems.selected = [];
      }
    },
    storeManageMyBookingFlightSearching: (state, action) => {
      state.manageMyBookingSearchingDetail = action.payload;
    },
    updateStepperActive: (state, action) => {
      state.stepperIndex = action.payload;
    },
    storeTrips: (state, action) => {
      state.trips = action.payload;
    },
    addTrips: (state, action) => {
      if (!state.trips?.find((trip) => trip.sales_reference === action.payload.sales_reference))
        state.trips?.push(action.payload);
    },
    updateTrips: (state, action) => {
      const tripIndex = state.trips?.findIndex((trip) => trip.sales_reference === action.payload.sales_reference);
      if (state.trips && tripIndex !== null && tripIndex !== undefined) {
        state.trips[tripIndex] = action.payload;
      }
    },
    storeChangeFlightTripArItems: (state, action) => {
      if (state.changeFlightTripDetail?.arItems) {
        state.changeFlightTripDetail.arItems.available = action.payload;
      }
    },
    storePassengerDetail: (state, { payload: { tripId, data } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1 && state.trips[tripIdx]) {
          state.trips[tripIdx].passengers = data.passengers;
        }
      }
    },
    cleanPassengerDetail: (state, { payload: { tripId } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1 && state.trips[tripIdx]) {
          state.trips[tripIdx].passengers = [];
        }
      }
    },
    updatePaxSeat: (
      state,
      {
        payload: {
          tripId,
          selectedJourneyKey,
          selectedSegmentKey,
          paxId,
          seatPrice,
          purchasedSeatPrice,
          seatDesignator,
          unitKey,
        },
      }
    ) => {
      if (tripId && selectedJourneyKey && selectedSegmentKey) {
        const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
          (e) =>
            e.journeyKey === selectedJourneyKey &&
            e.segmentKey === selectedSegmentKey &&
            e.tripId === tripId &&
            e.type === EArItemType.SEAT
        );

        if (currentItem) {
          const newSeat = {
            type: EArItemType.SEAT,
            code: seatDesignator,
            unit_key: unitKey,
            price: seatPrice,
            spoilage: purchasedSeatPrice,
            byPax: [
              {
                paxId,
                amount: 1,
              },
            ],
          };
          const paxIndex = currentItem.arItems.findIndex((arItem) => arItem.byPax?.some((pax) => pax.paxId === paxId));

          if (paxIndex > -1) {
            currentItem.arItems = currentItem.arItems.map((arItem, index) => (index === paxIndex ? newSeat : arItem));
          } else {
            currentItem.arItems.push(newSeat);
          }
        } else {
          state.tripDetailPageMetaData.selectedArItems.push({
            tripId: tripId,
            journeyKey: selectedJourneyKey,
            segmentKey: selectedSegmentKey,
            arItems: [
              {
                type: EArItemType.SEAT,
                code: seatDesignator,
                unit_key: unitKey,
                price: seatPrice,
                spoilage: purchasedSeatPrice,
                byPax: [
                  {
                    paxId,
                    amount: 1,
                  },
                ],
              },
            ],
            type: EArItemType.SEAT,
          });
        }
      }
    },
    removePaxSeat: (state, { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId } }) => {
      if (tripId && selectedJourneyKey && selectedSegmentKey) {
        const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
          (e) =>
            e.journeyKey === selectedJourneyKey &&
            e.segmentKey === selectedSegmentKey &&
            e.tripId === tripId &&
            e.type === EArItemType.SEAT
        );

        if (currentItem) {
          currentItem.arItems = currentItem.arItems.filter(
            (arItem) => !arItem.byPax?.some((pax) => pax.paxId === paxId)
          );
        }
      }
    },
    confirmPaxSeat: (state, { payload }) => {
      const currentTrip = state.trips?.find((n) => n.sales_reference === payload.tripId);

      currentTrip?.journeys.forEach((journey) => {
        if (journey.journey_key === payload.journeyKey) {
          journey.segments.forEach((segment) => {
            remove(segment.arItems.selected, (e) => e.type === EArItemType.SEAT);
            payload.arItems.forEach((arItem: ArItem & { segmentKey: string }) => {
              if (arItem.segmentKey === segment.segment_key) {
                segment.arItems.selected.push({
                  type: arItem.type,
                  code: arItem.code,
                  price: arItem.price,
                  spoilage: arItem.spoilage,
                  byPax: arItem.byPax,
                  unit_key: arItem.unit_key,
                });
              }
            });
          });
        }
      });
    },
    updatePaxMeal: (
      state,
      { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }
    ) => {
      if (tripId && selectedJourneyKey && selectedSegmentKey) {
        const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
          (e) =>
            e.journeyKey === selectedJourneyKey &&
            e.segmentKey === selectedSegmentKey &&
            e.tripId === tripId &&
            e.arItems.find((n) => n.type === EArItemType.MEAL)
        );
        if (currentItem) {
          const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
          if (mealSection) {
            const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
            if (mealSelectedSection) {
              mealSelectedSection.amount = amount;
            } else {
              mealSection.byPax?.push({
                paxId,
                amount,
              });
            }
          } else {
            currentItem.arItems.push({
              type: EArItemType.MEAL,
              code: mealCode,
              byPax: [
                {
                  paxId,
                  amount,
                },
              ],
            });
          }
        } else {
          state.tripDetailPageMetaData.selectedArItems.push({
            tripId: tripId,
            journeyKey: selectedJourneyKey,
            segmentKey: selectedSegmentKey,
            arItems: [
              {
                type: EArItemType.MEAL,
                code: mealCode,
                byPax: [
                  {
                    paxId,
                    amount,
                  },
                ],
              },
            ],
          });
        }
      }
    },
    confirmPaxMeal: (state, { payload }) => {
      state.trips
        ?.find((n) => n.sales_reference === payload.tripId)
        ?.journeys.forEach((n) => {
          if (n.journey_key === payload.journeyKey) {
            n.segments.forEach((m) => {
              remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
              payload.arItems.forEach((arItem: ArItem & { segmentKey: string }) => {
                if (m.segment_key === arItem.segmentKey) {
                  m.arItems.selected = m.arItems.selected.concat(arItem);
                }
              });
            });
          }
        });
    },
    updateUFirst: (state, { payload: { tripId, passengers, added, journeyKey, code } }) => {
      remove(
        state.tripDetailPageMetaData.selectedArItems,
        (e) =>
          e.arItems.find((e) => e.type === EArItemType.U_FIRST) &&
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined
      );
      if (!added) {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.U_FIRST,
              code,
              byPax: passengers.map((e: { id: any }) => ({
                paxId: e.id,
                amount: 1,
              })),
            },
          ],
        });
      }
    },
    confirmUFirst: (state, { payload }) => {
      if (state.trips) {
        state.trips.forEach((e) => {
          e.journeys.forEach((m) => {
            if (payload.journeyKey === m.journey_key && payload.tripId === e.sales_reference) {
              remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
              m.arItems.selected = m.arItems.selected.concat(payload.arItems);
            }
          });
        });
      }
    },
    updateSIMCard: (state, { payload: { tripId, added, paxId, code } }) => {
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmSIMCard: (state, { payload }) => {
      if (state.trips) {
        state.trips.forEach((e) => {
          if (payload.tripId === e.sales_reference) {
            remove(e.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
            e.arItems.selected = e.arItems.selected.concat(payload.arItems);
          }
        });
      }
    },
    updateSport: (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmSport: (state, { payload }) => {
      if (state.trips) {
        state.trips.forEach((e) => {
          e.journeys.forEach((m) => {
            if (payload.journeyKey === m.journey_key && payload.tripId === e.sales_reference) {
              remove(m.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
              m.arItems.selected = m.arItems.selected.concat(payload.arItems);
            }
          });
        });
      }
    },
    updateBaggage: (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) =>
          e.journeyKey === journeyKey &&
          e.tripId === tripId &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
      );
      if (currentItem) {
        const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
        if (activeOption) {
          const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
          if (activeUserSection) {
            activeUserSection.amount =
              selectedBaggage.code === EBaggageArCode._0KG
                ? active
                  ? 0
                  : 1
                : activeUserSection.amount + (active ? -1 : 1);
          } else {
            activeOption?.byPax?.push({
              paxId: paxId,
              amount: active ? 0 : 1,
            });
          }
        } else {
          currentItem.arItems.push({
            type: selectedBaggage.type,
            code: selectedBaggage.code,
            byPax: [
              {
                paxId: paxId,
                amount: active ? 0 : 1,
              },
            ],
          });
        }
        if (!active) {
          if (selectedBaggage.code === EBaggageArCode._0KG) {
            const options = currentItem.arItems.filter((e) => e.code !== selectedBaggage.code);
            options?.forEach((item) => {
              const userSection = item.byPax?.find((e) => e.paxId === paxId);
              if (userSection) {
                userSection.amount = 0;
              }
            });
          } else {
            const zeroOption = currentItem.arItems.find((e) => e.code === EBaggageArCode._0KG);
            if (zeroOption) {
              const userSection = zeroOption.byPax?.find((e) => e.paxId === paxId);
              if (userSection) {
                userSection.amount = 0;
              }
            }
          }
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          journeyKey,
          tripId,
          arItems: [
            {
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmBaggage: (state, { payload }) => {
      if (state.trips) {
        state.trips?.forEach((n) => {
          n.journeys.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
            payload?.forEach(
              (e: {
                journeyKey: string;
                tripId: string;
                arItems: ConcatArray<{
                  type: string;
                  code: string;
                  price?: number | undefined;
                  currency_code?: string | undefined;
                  availability?: number | null | undefined;
                  cms_content_key?: string | undefined;
                  ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
                  is_pre_order?: boolean | undefined;
                  is_vegetarian?: boolean | undefined;
                  byPax?: { paxId: string; amount: number }[] | undefined;
                  arMetaData?: { [x: string]: string | number | boolean } | undefined;
                  unit_key?: string | undefined;
                }>;
              }) => {
                if (m.journey_key === e.journeyKey && n.sales_reference === e.tripId) {
                  m.arItems.selected = m.arItems.selected.concat(e.arItems);
                }
              }
            );
          });
        });
      }
    },
    updateInsurance: (state, { payload: { tripId, passengers, code, added } }) => {
      const validPax = passengers?.filter((e: any) => e.paxType === EPaxType.Adult);
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) => e.tripId === tripId && e.type === EArItemType.INSURANCE
      );
      if (currentItem) {
        if (!added) {
          currentItem.arItems = [];
        } else if (added) {
          currentItem.arItems.push({
            type: EArItemType.INSURANCE,
            code,
            byPax: validPax?.map((e: any) => ({
              amount: 1,
              paxId: e.passenger_key,
            })),
          });
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId: tripId,
          arItems: [
            {
              type: EArItemType.INSURANCE,
              code: code,
              byPax: validPax?.map((e: any) => ({
                amount: 1,
                paxId: e.passenger_key,
              })),
            },
          ],
          type: EArItemType.INSURANCE,
        });
      }
    },
    confirmInsurance: (state, { payload: { tripId, passengers, code } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1) {
          const validPax = passengers.filter((e: Pax) => e.paxType === EPaxType.Adult);
          const selectedCopy = cloneDeep(state.trips[tripIdx].arItems.selected);
          remove(selectedCopy, (e) => e.type === EArItemType.INSURANCE);
          const isInsuranceSelected = state.tripDetailPageMetaData.selectedArItems.some(
            (e) => e.type === EArItemType.INSURANCE && e.arItems.length > 0
          );

          if (isInsuranceSelected) {
            selectedCopy.push({
              type: EArItemType.INSURANCE,
              code,
              byPax: validPax.map((e: { id: any }) => ({
                paxId: e.id,
                amount: 1,
              })),
            });
          }

          state.trips[tripIdx].arItems.selected = selectedCopy;
        }
      }
    },
    updateCarbonOffset: (state, { payload: { tripId, passengers, code } }) => {
      const currentItem = state.tripDetailPageMetaData.selectedArItems.find(
        (e) => e.tripId === tripId && e.type === EArItemType.CARBON_OFFSET
      );
      if (currentItem) {
        if (currentItem.arItems.some((e) => e.type === EArItemType.CARBON_OFFSET)) {
          currentItem.arItems = [];
        } else {
          currentItem.arItems.push({
            type: EArItemType.CARBON_OFFSET,
            code,
            byPax: [{ amount: 1, paxId: passengers[0].id }],
          });
        }
      } else {
        state.tripDetailPageMetaData.selectedArItems.push({
          tripId: tripId,
          arItems: [
            {
              type: EArItemType.CARBON_OFFSET,
              code: code,
              byPax: [{ amount: 1, paxId: passengers[0].id }],
            },
          ],
          type: EArItemType.CARBON_OFFSET,
        });
      }
    },
    confirmCarbonOffset: (state, { payload: { tripId, passengers, code } }) => {
      if (state.trips) {
        const tripIdx = state.trips.findIndex((e) => e.sales_reference === tripId);
        if (tripIdx > -1) {
          const selectedCopy = cloneDeep(state.trips[tripIdx].arItems.selected);
          remove(selectedCopy, (e) => e.type === EArItemType.CARBON_OFFSET);
          const isCarbonOffsetSelected = state.tripDetailPageMetaData.selectedArItems.some(
            (e) => e.type === EArItemType.CARBON_OFFSET && e.arItems.length > 0
          );
          if (isCarbonOffsetSelected) {
            selectedCopy.push({
              type: EArItemType.CARBON_OFFSET,
              code,
              byPax: [{ amount: 1, paxId: passengers[0].id }],
            });
          }
          state.trips[tripIdx].arItems.selected = selectedCopy;
        }
      }
    },
    updateTripDetailPageMetaData: (state, action) => {
      const { baggageSelected } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach((e) => {
          if (state) {
            if (state.tripDetailPageMetaData) {
              if (state.tripDetailPageMetaData.baggageSelected) {
                state.tripDetailPageMetaData.baggageSelected[e] = baggageSelected[e];
              } else {
                state.tripDetailPageMetaData["baggageSelected"] = baggageSelected;
              }
            }
          }
        });
      }
    },
    updatePassengerResultActive: (state, action) => {
      if (state.tripDetailPageMetaData) {
        if (state.tripDetailPageMetaData.updatePassengerResult) {
          state.tripDetailPageMetaData.updatePassengerResult = action.payload;
        } else {
          state.tripDetailPageMetaData["updatePassengerResult"] = action.payload;
        }
      }
    },
    resetMMBState: () => initialState,
    storeChangeFlightTripDetail: (state, action) => {
      state.changeFlightTripDetail = action.payload;
    },
    storeChangeFlightAdditional: (state, action) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail = {
          ...state.changeFlightTripDetail,
          journeys: state.changeFlightTripDetail.journeys,
          fees: action.payload.fees,
          notTransferSSR: action.payload.notTransferSSR,
          changeFlightJourneyDate: action.payload.changeFlightJourneyDate,
        };
        if (
          !action.payload.changeFlightJourneyDate?.first_journey &&
          action.payload.changeFlightJourneyDate?.second_journey
        ) {
          state.changeFlightTripDetail.journeys[0].index = 1;
        }
      }
    },
    removeChangeFlightTripDetail: (state, action) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail.journeys = state.changeFlightTripDetail?.journeys.filter((journey) => {
          return !action.payload.includes(journey.journey_key);
        });
      }
    },
    pushMMBJourney: (state, action) => {
      state.changeFlightTripDetail &&
        state.changeFlightTripDetail.journeys[action.payload.index === 1 ? "push" : "unshift"](action.payload);
    },
    storeMMBPaymentMetaData: (state, action) => {
      state.paymentMetaData = { ...state.paymentMetaData, ...action.payload };
    },
    storeMMBPaymentOptions: (state, action) => {
      if (state.paymentMetaData) {
        state.paymentMetaData.payment_option_list = action.payload;
      }
    },
    resetMMBPaymentMetaData: (state) => {
      state.paymentMetaData = undefined;
    },
    updateChangeFlightAddExtrasPageMetaData: (state, action) => {
      const { baggageSelected, confirmedItems } = action.payload;
      if (baggageSelected) {
        Object.keys(baggageSelected).forEach(
          (e) => (state.changeFlightAddExtraMetaData.baggageSelected[e] = baggageSelected[e])
        );
      }
      if (confirmedItems) {
        state.changeFlightAddExtraMetaData.confirmedItems = {
          ...state.changeFlightAddExtraMetaData.confirmedItems,
          ...confirmedItems,
        };
      }
    },
    updateChangeFlightBaggage: (state, { payload: { journeyKey, selectedBaggage, paxId, active, tripId } }) => {
      const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.BAGGAGE)
      );
      if (currentItem) {
        const activeOption = currentItem.arItems.find((e) => e.code === selectedBaggage.code);
        if (activeOption) {
          const activeUserSection = activeOption.byPax?.find((e) => e.paxId === paxId);
          if (activeUserSection) {
            activeUserSection.amount =
              selectedBaggage.code === EBaggageArCode._0KG
                ? active
                  ? 0
                  : 1
                : activeUserSection.amount + (active ? -1 : 1);
          } else {
            activeOption?.byPax?.push({
              paxId: paxId,
              amount: active ? 0 : 1,
            });
          }
        } else {
          currentItem.arItems.push({
            type: selectedBaggage.type,
            code: selectedBaggage.code,
            byPax: [
              {
                paxId: paxId,
                amount: active ? 0 : 1,
              },
            ],
          });
        }
      } else {
        state.changeFlightAddExtraMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: selectedBaggage.type,
              code: selectedBaggage.code,
              byPax: [
                {
                  paxId: paxId,
                  amount: active ? 0 : 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmChangeFlightSelectedBaggage: (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.BAGGAGE);
          payload?.forEach(
            (e: {
              journeyKey: string;
              arItems: ConcatArray<{
                type: string;
                code: string;
                price?: number | undefined;
                currency_code?: string | undefined;
                availability?: number | null | undefined;
                cms_content_key?: string | undefined;
                ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
                is_pre_order?: boolean | undefined;
                is_vegetarian?: boolean | undefined;
                byPax?: { paxId: string; amount: number }[] | undefined;
                arMetaData?: { [x: string]: string | number | boolean } | undefined;
                unit_key?: string | undefined;
              }>;
            }) => {
              if (m.journey_key === e.journeyKey) {
                m.arItems.selected = m.arItems.selected.concat(e.arItems);
              }
            }
          );
        });
      }
    },
    updateChangeFlightPaxMeal: (
      state,
      { payload: { tripId, selectedJourneyKey, selectedSegmentKey, paxId, mealCode, amount } }
    ) => {
      if (tripId && selectedJourneyKey && selectedSegmentKey) {
        const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
          (e) =>
            e.journeyKey === selectedJourneyKey &&
            e.segmentKey === selectedSegmentKey &&
            e.tripId === tripId &&
            e.arItems.find((n) => n.type === EArItemType.MEAL)
        );
        if (currentItem) {
          const mealSection = currentItem.arItems.find((e) => e.code === mealCode);
          if (mealSection) {
            const mealSelectedSection = mealSection.byPax?.find((e) => e.paxId === paxId);
            if (mealSelectedSection) {
              mealSelectedSection.amount = amount;
            } else {
              mealSection.byPax?.push({
                paxId,
                amount,
              });
            }
          } else {
            currentItem.arItems.push({
              type: EArItemType.MEAL,
              code: mealCode,
              byPax: [
                {
                  paxId,
                  amount,
                },
              ],
            });
          }
        } else {
          state.changeFlightAddExtraMetaData.selectedArItems.push({
            tripId: tripId,
            journeyKey: selectedJourneyKey,
            segmentKey: selectedSegmentKey,
            arItems: [
              {
                type: EArItemType.MEAL,
                code: mealCode,
                byPax: [
                  {
                    paxId,
                    amount,
                  },
                ],
              },
            ],
          });
        }
      }
    },
    confirmChangeFlightPaxMeal: (state, { payload }) => {
      if (payload) {
        payload.forEach(
          (e: {
            journeyKey: string;
            segmentKey: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            state.changeFlightTripDetail?.journeys.forEach((n) => {
              if (n.journey_key === e.journeyKey) {
                n.segments.forEach((m) => {
                  if (m.segment_key === e.segmentKey) {
                    remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
                    m.arItems.selected = m.arItems.selected.concat(e.arItems);
                  }
                });
              }
            });
          }
        );
      } else {
        state.changeFlightTripDetail?.journeys.forEach((n) => {
          n.segments.forEach((m) => {
            remove(m.arItems.selected, (e) => e.type === EArItemType.MEAL);
          });
        });
        remove(state.changeFlightAddExtraMetaData.selectedArItems, (n) =>
          n.arItems.find((e) => e.type === EArItemType.MEAL)
        );
      }
    },
    updateChangeFlightSport: (state, { payload: { tripId, journeyKey, added, paxId, code } }) => {
      const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SPORT_EQUIPMENT)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SPORT_EQUIPMENT);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.changeFlightAddExtraMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.SPORT_EQUIPMENT,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmChangeFlightSport: (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail.journeys.forEach((e) => {
          remove(e.arItems.selected, (e) => e.type === EArItemType.SPORT_EQUIPMENT);
          payload.forEach(
            (m: {
              tripId: string;
              journeyKey: string;
              arItems: ConcatArray<{
                type: string;
                code: string;
                price?: number | undefined;
                currency_code?: string | undefined;
                availability?: number | null | undefined;
                cms_content_key?: string | undefined;
                ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
                is_pre_order?: boolean | undefined;
                is_vegetarian?: boolean | undefined;
                byPax?: { paxId: string; amount: number }[] | undefined;
                arMetaData?: { [x: string]: string | number | boolean } | undefined;
                unit_key?: string | undefined;
              }>;
            }) => {
              if (
                state.changeFlightTripDetail &&
                m.tripId === state.changeFlightTripDetail?.id &&
                m.journeyKey === e.journey_key
              ) {
                e.arItems.selected = e.arItems.selected.concat(m.arItems);
              }
            }
          );
        });
      }
    },
    updateChangeFlightUFirst: (state, { payload: { passengers, added, journeyKey, code, tripId } }) => {
      remove(
        state.changeFlightAddExtraMetaData.selectedArItems,
        (e) =>
          e.arItems.find((e) => e.type === EArItemType.U_FIRST) &&
          e.tripId === tripId &&
          e.journeyKey === journeyKey &&
          e.segmentKey === undefined
      );
      if (!added) {
        state.changeFlightAddExtraMetaData.selectedArItems.push({
          tripId,
          journeyKey,
          arItems: [
            {
              type: EArItemType.U_FIRST,
              code,
              byPax: passengers.map((e: { id: any }) => ({
                paxId: e.id,
                amount: 1,
              })),
            },
          ],
        });
      }
    },
    confirmChangeFlightUFirst: (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        state.changeFlightTripDetail?.journeys.forEach((m) => {
          remove(m.arItems.selected, (e) => e.type === EArItemType.U_FIRST);
          if (payload) {
            m.arItems.selected = m.arItems.selected.concat(
              payload.find(
                (e: { journeyKey: string; tripId: string | undefined }) =>
                  e.journeyKey === m.journey_key && e.tripId === state.changeFlightTripDetail?.id
              )?.arItems ?? []
            );
          }
        });
      }
    },
    updateChangeFlightSIMCard: (state, { payload: { tripId, added, paxId, code } }) => {
      const currentItem = state.changeFlightAddExtraMetaData.selectedArItems.find(
        (e) =>
          e.tripId === tripId &&
          e.journeyKey === undefined &&
          e.segmentKey === undefined &&
          e.arItems.find((n) => n.type === EArItemType.SIM_CARD)
      );
      if (currentItem) {
        const arItem = currentItem.arItems.find((e) => e.type === EArItemType.SIM_CARD);
        if (arItem?.byPax) {
          if (added) {
            remove(arItem.byPax, (e) => e.paxId === paxId);
          } else {
            arItem.byPax.push({
              paxId: paxId,
              amount: 1,
            });
          }
        } else {
          if (!added)
            currentItem.arItems.push({
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId: paxId,
                  amount: 1,
                },
              ],
            });
        }
      } else {
        state.changeFlightAddExtraMetaData.selectedArItems.push({
          tripId,
          arItems: [
            {
              type: EArItemType.SIM_CARD,
              code,
              byPax: [
                {
                  paxId,
                  amount: 1,
                },
              ],
            },
          ],
        });
      }
    },
    confirmChangeFlightSIMCard: (state, { payload }) => {
      if (state.changeFlightTripDetail) {
        remove(state.changeFlightTripDetail.arItems.selected, (e) => e.type === EArItemType.SIM_CARD);
        payload.forEach(
          (n: {
            tripId: string;
            arItems: ConcatArray<{
              type: string;
              code: string;
              price?: number | undefined;
              currency_code?: string | undefined;
              availability?: number | null | undefined;
              cms_content_key?: string | undefined;
              ssr_by_passengers?: { ssr_key: string; price: number; passenger_key: string }[] | undefined;
              is_pre_order?: boolean | undefined;
              is_vegetarian?: boolean | undefined;
              byPax?: { paxId: string; amount: number }[] | undefined;
              arMetaData?: { [x: string]: string | number | boolean } | undefined;
              unit_key?: string | undefined;
            }>;
          }) => {
            if (state.changeFlightTripDetail && n.tripId === state.changeFlightTripDetail?.id) {
              state.changeFlightTripDetail.arItems.selected = state.changeFlightTripDetail?.arItems.selected.concat(
                n.arItems
              );
            }
          }
        );
      }
    },
    updateChangeFlightInsurance: (state, action) => {
      const validPax = state.changeFlightTripDetail?.passengers?.filter((e) => e.paxType === "Adult");
      if (
        state.changeFlightTripDetail &&
        action.payload &&
        action.payload === 1 &&
        !state.changeFlightTripDetail.arItems.selected.find((e) => e.type === EArItemType.INSURANCE)
      ) {
        state.changeFlightTripDetail.arItems.selected.push({
          type: EArItemType.INSURANCE,
          code: EArItemType.INSURANCE,
          byPax: validPax?.map((e) => ({
            amount: 1,
            paxId: e.id,
          })),
        });
      } else if (state.changeFlightTripDetail && action.payload !== 1) {
        state.changeFlightTripDetail.arItems.selected = state.changeFlightTripDetail?.arItems.selected.filter(
          (e) => e.type !== EArItemType.INSURANCE
        );
      }
    },
    updateChangeFlightCarbonOffset: (state, action) => {
      if (state.changeFlightTripDetail && action.payload === true) {
        state.changeFlightTripDetail.arItems.selected.push({
          type: EArItemType.CARBON_OFFSET,
          code: EArItemType.CARBON_OFFSET,
          byPax: [
            {
              amount: 1,
              paxId: state?.changeFlightTripDetail.passengers[0].id,
            },
          ],
        });
      } else if (state.changeFlightTripDetail && action.payload === false) {
        state.changeFlightTripDetail.arItems.selected = state.changeFlightTripDetail?.arItems.selected.filter(
          (e) => e.type !== EArItemType.CARBON_OFFSET
        );
      }
    },
    addChangeFlightSelectedSeat: (state, action) => {
      state.changeFlightTripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          let seatFlag = false;
          journey.segments[segmentIndex].arItems.selected.forEach((arItem) => {
            if (arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId) {
              arItem.code = action.payload.seatDesignator;
              arItem.price = action.payload.seatPrice;
              arItem.unit_key = action.payload.unitKey;
              arItem.currency_code = action.payload.currency_code;
              seatFlag = true;
            }
          });
          if (!seatFlag) {
            journey.segments[segmentIndex].arItems.selected.push({
              type: "SEAT",
              code: action.payload.seatDesignator,
              price: action.payload.seatPrice,
              unit_key: action.payload.unitKey,
              currency_code: action.payload.currency_code,
              byPax: [
                {
                  paxId: action.payload.paxId,
                  amount: 1,
                },
              ],
            });
          }
        }
      });
    },
    removeChangeFlightSelectedSeat: (state, action) => {
      state.changeFlightTripDetail?.journeys.forEach((journey) => {
        const segmentIndex = journey.segments.findIndex((segment) => segment.segment_key === action.payload.segmentKey);
        if (segmentIndex > -1) {
          const arItems = journey.segments[segmentIndex].arItems.selected.filter((arItem) => {
            return !(arItem.type === "SEAT" && arItem.byPax && arItem.byPax[0].paxId === action.payload.paxId);
          });
          journey.segments[segmentIndex].arItems.selected = arItems;
        }
      });
    },
    storeMMBTripArItems: (state, action) => {
      state.trips?.find((trip) => trip.sales_reference === action.payload)?.arItems.selected.push(action.payload);
    },
    resetMMBTripDetailPageMetaDate: (state) => {
      state.tripDetailPageMetaData = { ...state.tripDetailPageMetaData, baggageSelected: {}, selectedArItems: [] };
    },
    storeMMBPaymentPageDetail: (state, action) => {
      state.paymentPageDetail = {
        ...state.paymentPageDetail,
        ...action.payload,
      };
    },
    updateMMBPaymentPageDetail: (state, action) => {
      if (state.paymentPageDetail) {
        Object.keys(action.payload).forEach((e) => {
          state.paymentPageDetail[e as keyof PaymentPageDetail] = action.payload[e];
        });
      }
    },
    updateChangeFlightAnalyticPaymentDetail: (state, action) => {
      if (state.changeFlightAnalyticPaymentDetail && action.payload) {
        state.changeFlightAnalyticPaymentDetail = {
          ...state.changeFlightAnalyticPaymentDetail,
          ...action.payload,
        };
      } else {
        state.changeFlightAnalyticPaymentDetail = action.payload;
      }
    },
    updateMMBAnalyticPaymentDetail: (state, action) => {
      if (state.mmbAnalyticPaymentDetail && action.payload) {
        state.mmbAnalyticPaymentDetail = {
          ...state.mmbAnalyticPaymentDetail,
          ...action.payload,
        };
      } else {
        state.mmbAnalyticPaymentDetail = action.payload;
      }
    },
    storeEPaymentFee: (state, action) => {
      if (state.paymentMetaData) {
        state.paymentMetaData.selected_e_payment_fee = action.payload;
      }
    },
    resetMMBTripList: (state) => {
      state.trips = [];
    },
  },
  selectors: {
    selectManageMyBookingSearchingDetail: (
      storage: ManageMyBookingReducerProps
    ): ManageMyBookingSearchingDetail | undefined => storage.manageMyBookingSearchingDetail,
    selectStepperActive: (storage: ManageMyBookingReducerProps): number => storage.stepperIndex,
    selectCurrentTrip: (
      storage: ManageMyBookingReducerProps,
      bookingRef: string
    ): ManageMyBookingTripDetail | undefined => storage.trips?.find((trip) => trip.sales_reference === bookingRef),
    selectPassengerDetail: (storage: ManageMyBookingReducerProps, bookingRef: string): MMBPax[] | undefined =>
      storage.trips?.find((trip) => trip.sales_reference === bookingRef)?.passengers,
    selectUpdatePassengerResult: (storage: ManageMyBookingReducerProps): boolean =>
      storage.tripDetailPageMetaData?.updatePassengerResult ?? false,
    // selectMMBNskTokenNotify: (storage: ManageMyBookingReducerProps) => storage.mmbNskTokenExpireNotify,
    selectAllMMBTrips: (storage: ManageMyBookingReducerProps) => storage.trips,
    getChangeFlightJourneys: (storage: ManageMyBookingReducerProps): Journey[] | undefined =>
      storage.changeFlightTripDetail?.journeys,
    selectJourneyFlightStatus: (storage: ManageMyBookingReducerProps) => {
      const journeys = storage.changeFlightTripDetail?.journeys;
      const flightStatusData: Journey[] = [];
      if (journeys) {
        journeys.forEach((journey) => {
          flightStatusData.push({
            journey_key: journey.journey_key,
            segments: journey.segments,
            fare: journey.fare,
            origin: journey.origin,
            display_item_amount: journey.display_item_amount,
            destination: journey.destination,
            date: journey.date,
            flight_time: journey.flight_time,
            index: journey.index,
            duration: journey.duration,
            arItems: {
              available: [],
              selected: [],
              purchased: [],
            },
            arrival_date_time: journey.arrival_date_time,
            departure_date_time: journey.departure_date_time,
          });
        });
        return flightStatusData;
      }
      return undefined;
    },
    selectChangeFlightTripDetail: (
      storage: ManageMyBookingReducerProps
    ):
      | (TripDetail & {
          passengers: IBEPax[];
          notTransferSSR: any[];
          fees: Fees;
          changeFlightJourneyDate: {
            first_journey?: ChangeFlightJourneyDate;
            second_journey?: ChangeFlightJourneyDate;
          };
        })
      | undefined => storage.changeFlightTripDetail,
    selectChangeFlightAddExtrasPageMetaData: (
      storage: ManageMyBookingReducerProps
    ): ChangeFlightAddExtrasPageMetaData | undefined => storage.changeFlightAddExtraMetaData,
    selectTripDetailPageMetaData: (storage: ManageMyBookingReducerProps): TripDetailPageMetaData | undefined =>
      storage.tripDetailPageMetaData,
    selectMMBPaymentOptions: (storage: ManageMyBookingReducerProps): PaymentOption[] | undefined =>
      storage.paymentMetaData?.payment_option_list,
    selectMMBInsuranceQuote: (storage: ManageMyBookingReducerProps) => storage.paymentMetaData?.insurance_quote,
    selectMMBCarbonQuote: (storage: ManageMyBookingReducerProps) => storage.paymentMetaData?.carbon_quote,
    selectChangeFlightFees: (storage: ManageMyBookingReducerProps) => storage.changeFlightTripDetail?.fees,
    selectMMBPaymentPageDetail: (storage: ManageMyBookingReducerProps) => storage.paymentPageDetail,
    selectChangeFlightAnalyticPaymentDetail: (storage: ManageMyBookingReducerProps) =>
      storage.changeFlightAnalyticPaymentDetail,
    selectMMBAnalyticPaymentDetail: (storage: ManageMyBookingReducerProps) => storage.mmbAnalyticPaymentDetail,
    selectMMBPaymentMetaData: (storage: ManageMyBookingReducerProps) => storage.paymentMetaData,
  },
});

export const {
  resetChangeFlightStep2State,
  resetChangeFlightStep3State,
  resetChangeFlightStep4State,
  storeManageMyBookingFlightSearching,
  updateStepperActive,
  storeTrips,
  addTrips,
  updateTrips,
  storePassengerDetail,
  cleanPassengerDetail,
  updatePaxMeal,
  confirmPaxMeal,
  updateUFirst,
  confirmUFirst,
  updateSIMCard,
  confirmSIMCard,
  updateSport,
  confirmSport,
  updateBaggage,
  confirmBaggage,
  updateInsurance,
  updateCarbonOffset,
  updateTripDetailPageMetaData,
  updatePassengerResultActive,
  resetMMBState,
  storeChangeFlightTripDetail,
  removeChangeFlightTripDetail,
  pushMMBJourney,
  storeMMBPaymentMetaData,
  updateChangeFlightAddExtrasPageMetaData,
  updateChangeFlightBaggage,
  confirmChangeFlightSelectedBaggage,
  updateChangeFlightPaxMeal,
  confirmChangeFlightPaxMeal,
  updateChangeFlightSport,
  confirmChangeFlightSport,
  updateChangeFlightUFirst,
  confirmChangeFlightUFirst,
  updateChangeFlightSIMCard,
  confirmChangeFlightSIMCard,
  addChangeFlightSelectedSeat,
  removeChangeFlightSelectedSeat,
  storeChangeFlightAdditional,
  storeChangeFlightTripArItems,
  resetMMBPaymentMetaData,
  resetMMBTripDetailPageMetaDate,
  updatePaxSeat,
  removePaxSeat,
  confirmPaxSeat,
  storeMMBPaymentOptions,
  storeMMBPaymentPageDetail,
  updateMMBPaymentPageDetail,
  confirmCarbonOffset,
  confirmInsurance,
  updateChangeFlightAnalyticPaymentDetail,
  updateMMBAnalyticPaymentDetail,
  storeEPaymentFee,
  updateChangeFlightInsurance,
  updateChangeFlightCarbonOffset,
  resetMMBTripList,
} = manageMyBookingSlice.actions;
export default manageMyBookingSlice.reducer;
export const {
  selectManageMyBookingSearchingDetail,
  selectStepperActive,
  selectCurrentTrip,
  selectPassengerDetail,
  selectUpdatePassengerResult,
  selectAllMMBTrips,
  getChangeFlightJourneys,
  selectJourneyFlightStatus,
  selectChangeFlightTripDetail,
  selectChangeFlightAddExtrasPageMetaData,
  selectTripDetailPageMetaData,
  selectMMBPaymentOptions,
  selectChangeFlightFees,
  selectMMBInsuranceQuote,
  selectMMBCarbonQuote,
  selectMMBPaymentPageDetail,
  selectChangeFlightAnalyticPaymentDetail,
  selectMMBAnalyticPaymentDetail,
  selectMMBPaymentMetaData,
} = manageMyBookingSlice.getSelectors((state: RootState) => {
  return state.session.manageMyBooking;
});

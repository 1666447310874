import { formatISO } from "date-fns";
import {
  CreateOrderResponse,
  CreatePaymentApiProps,
  ErrorType,
  GetOrderStatusResponse,
} from "lib/features/flight-book/payment/definition";
import { isCCMethod } from "lib/features/flight-book/payment/helper";
import {
  createCCpayment,
  createPayment,
  creditDataExchange,
  encryptWithJwk,
  generateSignature,
  getOrderStatus,
} from "lib/features/flight-book/payment/service";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { InitMMBPaymentSaga } from "lib/features/manage-my-booking/my-trips/payment/definition";
import { constructMMBOrderCreateBody } from "lib/features/manage-my-booking/my-trips/payment/helper";
import { orderCreateAddAncilieo } from "lib/features/manage-my-booking/my-trips/service";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { Effect, call, cancel, delay, fork, put, spawn, take, takeEvery } from "redux-saga/effects";
import { RouterInstance } from "router/router-utils";
import { storeCCPaymentMetaData } from "store/sessionStorage/slices/ccPaymentMetaData";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { updateMMBAnalyticPaymentDetail } from "store/sessionStorage/slices/manageMyBookingSlice";
import { v4 } from "uuid";
import { INIT_MMB_PAYMENT_SAGA, createMMBFormAction } from "../actions";

function* createMMBOrderId(
  mileAmount = 0,
  cash_amount = 0,
  currency: string,
  tripCost: number,
  booking: ManageMyBookingTripDetail,
  insurance?: number,
  carbon_offset?: number
): Generator<Effect, void, any> {
  try {
    const requestBody = yield call(constructMMBOrderCreateBody, {
      booking,
      mileAmount,
      cash_amount,
      currency,
      tripCost,
      insurance,
      carbon_offset,
    });

    const { order_id, order_type }: CreateOrderResponse = yield call(orderCreateAddAncilieo, requestBody);
    if (order_id) {
      yield put({ type: "CREATE_MMB_PAYMENT_SAGA", order_id, order_type });
    } else {
      yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
    }
  } catch (e) {
    yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
  }
}

function* initMMBCCpayment(
  { creditCardDetail, paymentMethod }: InitMMBPaymentSaga,
  order_id: string
): Generator<Effect, void, any> {
  if (!creditCardDetail) return;
  const dataExchangeResp = yield call(creditDataExchange, {
    AccountNumber: creditCardDetail.cardNumber.replace(/\s/g, ""),
  });

  if (dataExchangeResp.jwt) {
    yield put(storeCCPaymentMetaData({ creditDataExchange: dataExchangeResp }));
  }
}

function* createMMBCCpaymentSaga(
  paymentDetail: InitMMBPaymentSaga,
  order_id: string,
  order_type: string,
  tds_md: string,
  afy_tid: string
): Generator<Effect, void, any> {
  const { creditCardDetail, paymentMethod, convenience_fee = 0, ccToken, dcc_key, dcc_accept_offer } = paymentDetail;
  if (!creditCardDetail || !ccToken) return;
  const { cardNumber, expiryDate, cardHolderName, cv2Number } = creditCardDetail;
  const nonce = v4();
  const timestamp = formatISO(new Date());
  const CCPaymentRequestBody = {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: "http://localhost:8080/success",
    failure_url: "http://localhost:8080/failure",
    payment_option: paymentMethod,
    convenience_fee,
    dcc_key,
    cc_payload: {
      card_number: encryptWithJwk(ccToken, cardNumber.replace(/\s/g, "")),
      card_expiry: encryptWithJwk(ccToken, expiryDate),
      card_cvc: encryptWithJwk(ccToken, cv2Number),
      card_holder_name: cardHolderName,
      nonce: nonce,
      timestamp: timestamp,
      sig: generateSignature(cardNumber.replace(/\s/g, ""), expiryDate, cv2Number, nonce, timestamp),
      tds_md,
      afy_tid,
    },
  };
  const rest = yield call(createCCpayment, CCPaymentRequestBody);
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_MMB_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
  }
  return rest;
}

function* createMMBPaymentSaga(
  order_id: string,
  order_type: string,
  paymentDetail: InitMMBPaymentSaga
): Generator<Effect, void, any> {
  const { paymentMethod, convenience_fee = 0 } = paymentDetail;
  if (!paymentMethod) {
    yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
    return;
  }
  const rest = yield call(createPayment, {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: "http://localhost:3000/en/confirmation",
    failure_url: "http://localhost:3000/en/confirmation",
    payment_option: paymentMethod,
    convenience_fee,
  } as CreatePaymentApiProps);
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_MMB_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
  }
  return rest;
}
let retryCount = 0;
let retryFirstPollingStatus = false;
function* retrieveOrderStatus(
  order_id: string,
  booking: ManageMyBookingTripDetail,
  order_type?: string,
  paymentDetail?: Partial<InitMMBPaymentSaga>
): Generator<Effect, void, any> {
  if (retryFirstPollingStatus) {
    yield delay(2000);
    retryFirstPollingStatus = false;
  }
  while (true) {
    const response: GetOrderStatusResponse = yield call(getOrderStatus, order_id);
    if (response.cash_payment_status === "F_AUTH" || (response.payment_error && response.payment_error.error_code)) {
      yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
      if (retryCount > 2) {
        RouterInstance.replace("`/:lang/manage-my-booking/my-trips/${booking.sales_reference}`");
        yield put(
          storeErrorModalDetail({
            type: ErrorType.M,
            title: "web.payment.paymentError3Times.error.title",
            desc: "web.payment.paymentError3Times.error.desc",
            onClose: () => {},
          })
        );
        break;
      } else {
        retryCount++;
        retryFirstPollingStatus = true;
        yield spawn(initMMBPaymentSaga, {
          payload: { repay_order_id: order_id, repay_order_type: order_type, ...paymentDetail },
        });
        yield put({ type: "CREATE_MMB_PAYMENT_SAGA", order_id, order_type });
      }
    } else {
      if (response.order_status === "F_COMP") {
        yield put(createMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
        RouterInstance.push(`/:lang/manage-my-booking/my-trips/${booking.sales_reference}`);
        yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
        break;
      } else if (response.order_status === "S_COMP") {
        yield put(createMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
        // yield put(storeTripDetailSalesReference(response.sales_reference));
        RouterInstance.push("/:lang/confirmation?" + new URLSearchParams({ typeFlow: "MMB" }));
        yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
        break;
      } else if (response.order_status === "PEND") {
        yield put(createMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
      } else {
        yield put({ type: "CANCEL_MMB_PAYMENT_SAGA" });
        if (retryCount > 2) {
          RouterInstance.replace(`/:lang/manage-my-booking/my-trips/${booking.sales_reference}`);
          yield put(
            storeErrorModalDetail({
              type: ErrorType.M,
              title: "web.payment.paymentError3Times.error.title",
              desc: "web.payment.paymentError3Times.error.desc",
              onClose: () => {},
            })
          );
          break;
        } else {
          retryCount++;
          retryFirstPollingStatus = true;
          yield spawn(initMMBPaymentSaga, {
            payload: { repay_order_id: order_id, repay_order_type: order_type, ...paymentDetail },
          });
          yield put({ type: "CREATE_MMB_PAYMENT_SAGA", order_id, order_type });
        }
      }
    }
    yield delay(5000);
  }
}

function* initMMBPaymentSaga(action: any): Generator<Effect, void, any> {
  yield put(showLoadingBackdrop());
  let createOrderTask = undefined;
  const {
    booking,
    cash_amount,
    mileAmount,
    paymentMethod,
    currency,
    tripCost,
    insurance,
    carbon_offset,
    repay_order_id,
    repay_order_type,
  } = action.payload as InitMMBPaymentSaga;
  if (!repay_order_id || !repay_order_type) {
    createOrderTask = yield fork(
      createMMBOrderId,
      mileAmount,
      cash_amount,
      currency,
      tripCost,
      booking,
      insurance,
      carbon_offset
    );
  }
  const { order_id, order_type } = yield take("CREATE_MMB_PAYMENT_SAGA");
  yield put(
    updateMMBAnalyticPaymentDetail({
      confirmation_code: order_id,
    })
  );
  if (createOrderTask) {
    yield cancel(createOrderTask);
  }
  let createPaymentTask: any;
  let orderStatusTask = undefined;

  if (order_type === "NO_PAYMENT_ORDER") {
    orderStatusTask = yield fork(retrieveOrderStatus, order_id, booking, order_type, action.payload);
  } else if (isCCMethod(paymentMethod)) {
    yield put(
      storeCCPaymentMetaData({
        orderNumber: order_id,
        amount: cash_amount,
      })
    );
    createPaymentTask = yield fork(initMMBCCpayment, action.payload, order_id);
    const { md, afy_tid } = yield take("CREATE_CC_PAYMENT");
    yield fork(createMMBCCpaymentSaga, action.payload, order_id, order_type, md, afy_tid);
  } else {
    createPaymentTask = yield fork(createMMBPaymentSaga, order_id, order_type, action.payload);
  }

  if (!orderStatusTask) {
    yield take("RETRIEVE_MMB_ORDER_STATUS");
    orderStatusTask = yield fork(retrieveOrderStatus, order_id, booking, order_type, action.payload);
  }

  yield take("CANCEL_MMB_PAYMENT_SAGA");
  yield put(closeLoadingBackdrop());
  yield cancel(createOrderTask);
  yield cancel(createPaymentTask);
  yield cancel(orderStatusTask);
}

export function* mmbPaymentSaga() {
  yield takeEvery(INIT_MMB_PAYMENT_SAGA, initMMBPaymentSaga);
}

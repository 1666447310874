import { createAction, createReducer, createSelector, isAnyOf } from "@reduxjs/toolkit";

export type GeneralState = {
  appReady: boolean;
  signInPopup: boolean;
  apiErrorCodes: string[];
  currencyOptionsShown: boolean;
};
const generalState: GeneralState = {
  appReady: false,
  signInPopup: false,
  apiErrorCodes: [],
  currencyOptionsShown: true,
};
export const GENERAL_KEY = "GENERAL";
export const APP_READY = `${GENERAL_KEY}/APP_READY`;
export const appReady = createAction<undefined>(APP_READY);
export const PUSH_API_ERROR_CODE = `${GENERAL_KEY}/PUSH_API_ERROR_CODE`;
export const pushApiErrorCode = createAction<string>(PUSH_API_ERROR_CODE);
export const RESET_API_ERROR_CODES = `${GENERAL_KEY}/RESET_API_ERROR_CODES`;
export const resetApiErrorCodes = createAction<undefined>(RESET_API_ERROR_CODES);
export const SHOW_CURRENCY_OPTIONS = `${GENERAL_KEY}/SHOW_CURRENCY_OPTIONS`;
export const showCurrencyOptions = createAction<undefined>(SHOW_CURRENCY_OPTIONS);
export const HIDE_CURRENCY_OPTIONS = `${GENERAL_KEY}/HIDE_CURRENCY_OPTIONS`;
export const hideCurrencyOptions = createAction<undefined>(HIDE_CURRENCY_OPTIONS);
export const OPEN_SIGN_IN_POPUP = `${GENERAL_KEY}/OPEN_SIGN_IN_POPUP`;
export const openSignInPopup = createAction<undefined>(OPEN_SIGN_IN_POPUP);
export const CLOSE_SIGN_IN_POPUP = `${GENERAL_KEY}/CLOSE_SIGN_IN_POPUP`;
export const closeSignInPopup = createAction<undefined>(CLOSE_SIGN_IN_POPUP);

export const selectGeneral = (state: { general: GeneralState }) => state.general;
export const selectAppReady = createSelector(selectGeneral, (general) => general.appReady);
export const selectApiErrorCodes = createSelector(selectGeneral, (general) => general.apiErrorCodes);
export const selectSignInPopup = createSelector(selectGeneral, (general) => general.signInPopup);
export const selectCurrencyOptionsShown = createSelector(selectGeneral, (general) => general.currencyOptionsShown);

export const generalReducer = createReducer(generalState, (builder) => {
  builder.addMatcher(isAnyOf(appReady), (state) => {
    state.appReady = true;
  });
  builder.addMatcher(isAnyOf(pushApiErrorCode), (state, action) => {
    if (!state.apiErrorCodes.includes(action.payload)) {
      state.apiErrorCodes.push(action.payload);
    }
  });
  builder.addMatcher(isAnyOf(resetApiErrorCodes), (state) => {
    state.apiErrorCodes = [];
  });
  builder.addMatcher(isAnyOf(showCurrencyOptions), (state) => {
    state.currencyOptionsShown = true;
  });
  builder.addMatcher(isAnyOf(hideCurrencyOptions), (state) => {
    state.currencyOptionsShown = false;
  });
  builder.addMatcher(isAnyOf(openSignInPopup), (state) => {
    state.signInPopup = true;
  });
  builder.addMatcher(isAnyOf(closeSignInPopup), (state) => {
    state.signInPopup = false;
  });
});

"use client";
import {
  AdultIcon,
  Button,
  ButtonVariant,
  ChildIcon,
  EBorders,
  EFont,
  EFontSize,
  EPaxType,
  ESpacing,
  Font,
  InfantIcon,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack, SvgIcon } from "@mui/material";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { useTransContent } from "modules/common/trans-content/transContent";
import { getStoredJourneyArItems, useJourneyCost } from "modules/features/flightBooking/calculateCost/useJourneyCost";
import { useSeatCost } from "modules/features/flightBooking/calculateCost/useSeatCost";
import { getStoredSegmentsArItems, useSegmentCost } from "modules/features/flightBooking/calculateCost/useSegmentsCost";
import { getStoredTripArItems, useTripCost } from "modules/features/flightBooking/calculateCost/useTripCost";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectTripDetail } from "store/sessionStorage/slices/flightBookingSlice";
import { EArItemType, JourneyConf } from "./d";
export const paxTypeIconMapping: { [key in EPaxType]: JSX.Element } = {
  [EPaxType.Adult]: (
    <SvgIcon>
      <AdultIcon />
    </SvgIcon>
  ),
  [EPaxType.Child]: (
    <SvgIcon>
      <ChildIcon />
    </SvgIcon>
  ),
  [EPaxType.Infant]: (
    <SvgIcon>
      <InfantIcon />
    </SvgIcon>
  ),
};
export function NavButtons<D>({
  buttons,
  selected,
  onClick,
}: {
  buttons: JourneyConf<D>[];
  selected: string;
  onClick: (id: string, data?: D) => void;
}) {
  const [active, setActive] = useState(selected);
  const { t } = useTransContent();
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyItems='space-between'
      sx={{
        border: EBorders.b1,
        borderRadius: "48px",
        backgroundColor: "neutral.background",
        padding: ESpacing._3xs,
      }}
      spacing={ESpacing._2xs}
    >
      {buttons.map((e) => (
        <Box key={e.id} sx={{ flex: "auto" }}>
          <Button
            fullWidth
            onClick={() => {
              setActive(e.id);
              onClick && onClick(e.id, e.data);
            }}
            variant={e.id === active ? ButtonVariant.CTA : ButtonVariant.Text}
            borderRadius={70}
          >
            <Stack spacing={ESpacing._2xs} direction={["column", "column", "row"]} alignItems='baseline'>
              <Font
                variant={EFont.h3}
                color={e.id === active ? "neutral.white" : "neutral.black"}
                fontWeight='fontWeightBold'
              >
                {e.label}
              </Font>
              {e.selectAmount !== undefined && buttons.length < 3 && (
                <>
                  <Box
                    sx={{
                      borderRight: ["none", "none", EBorders.b1],
                      borderColor: e.id === active ? "neutral.white" : "neutral.black",
                      height: EFontSize.p2,
                      padding: `0 ${ESpacing._3xs}`,
                    }}
                  />
                  <Font
                    variant={EFont.p2}
                    color={e.id === active ? "neutral.white" : "neutral.black"}
                    fontWeight='400'
                    ml='8px !important'
                  >
                    {e.selectAmount + " " + t("web.flightBook.added")}
                  </Font>
                </>
              )}
            </Stack>
          </Button>
        </Box>
      ))}
    </Stack>
  );
}

export function useExtrasCost(trip?: ManageMyBookingTripDetail | TripDetail) {
  const storedTrips = useSelector(selectTripDetail);
  const tripDetail = trip ?? storedTrips;
  const tripArItems = getStoredTripArItems(tripDetail);
  const journeyArItems = getStoredJourneyArItems(tripDetail?.journeys);
  const segmentsArItems = getStoredSegmentsArItems(tripDetail?.journeys);
  // Baggage cost
  const baggageCost = useJourneyCost(EArItemType.BAGGAGE, journeyArItems);
  // meal cost
  const mealCost = useSegmentCost(EArItemType.MEAL, segmentsArItems);
  // u-first cost
  const uFirstCost = useJourneyCost(EArItemType.U_FIRST, journeyArItems);
  // sim card cost
  const simCardCost = useTripCost(EArItemType.SIM_CARD, tripArItems);
  // sport card cost
  const sportCost = useJourneyCost(EArItemType.SPORT_EQUIPMENT, journeyArItems);
  // seat cost
  const seatCost = useSeatCost(tripDetail?.journeys);
  // sms cost
  const smsCost = useJourneyCost(EArItemType.SMS, journeyArItems);

  return useMemo(
    () => simCardCost + uFirstCost + sportCost + baggageCost + mealCost + seatCost + smsCost,
    [simCardCost, uFirstCost, sportCost, baggageCost, mealCost, seatCost, smsCost]
  );
}
export * from "./addExtrasPage";
export * from "./d";

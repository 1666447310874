import { ArItem } from "@hkexpressairwayslimited/ui";
import { fetchAPI, getAccessTokenHeader, olciEndPonits } from "api";
import { requiredHeader } from "lib/common/service";
import { responseApiError } from "lib/features/flight-book/payment/definition";
import { ErrorCodeList, getErrorDetail } from "lib/features/flight-book/payment/errorCodeMapping";
import store from "store";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { APICreateOrderRequestBody, APIGetOnlineCheckInRequestBody } from "./definition";

export const retrieveBoardingPass = async (
  token: string,
  journeyKey: string,
  req: {
    sales_reference: string;
    first_name: string;
    last_name: string;
  }
) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(olciEndPonits.retrieveBoardingPass + journeyKey, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });
  return result;
};

export const getBookingSSR = async (req: APIGetOnlineCheckInRequestBody, token: string) => {
  const result = await fetchAPI(olciEndPonits.getBookingSSR, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });

  let simCard: ArItem[] = [];

  result.journeys[0].ssrs
    .map((ssr: any) => {
      if (ssr.type === EArItemType.SIM_CARD && ssr.is_offer && ssr.product.length > 0)
        simCard = ssr.product.map((ar: any) => ({
          type: ssr.type.toUpperCase(),
          code: ar.ssr_code,
          price: ar.ssr_by_passengers[0].price,
          availability: ar.available,
          currency_code: ar.currency_code,
          ssr_by_passengers: ar.ssr_by_passengers,
          limit_per_passenger: ar.limit_per_passenger,
        }));
    })
    .flat();

  const tripSSR = result.trip;

  const remappedResult = result.journeys.map((journey: any, i: number) => {
    return {
      ...journey,
      ssrs: journey.ssrs
        .flatMap((ssr: any) => {
          if (ssr.is_offer)
            return ssr.product
              .filter((ar: any) => ar.ssr_code !== "SIM_CARD")
              .map((ar: any) => {
                return {
                  type: ssr.type,
                  code: ar.ssr_code,
                  price: ar.ssr_by_passengers[0].price,
                  availability: ar.available,
                  cms_content_key: ar.cms_content_key,
                  currency_code: ar.currency_code,
                  ssr_by_passengers: ar.ssr_by_passengers,
                  limit_per_passenger: ar.limit_per_passenger,
                };
              });
        })
        .filter(Boolean),
      segments: journey.segments.map((segment: any) => {
        return {
          ...segment,
          ssrs: segment.ssrs?.flatMap((ssr: any) => {
            if (ssr.is_offer)
              return ssr.product.map((ar: any) => ({
                type: ssr.type,
                code: ar.ssr_code,
                price: ar.ssr_by_passengers[0].price,
                availability: ar.available,
                cms_content_key: ar.cms_content_key,
                currency_code: ar.currency_code,
                ...(ar.is_pre_order !== undefined && { is_pre_order: ar.is_pre_order }),
                ...(ar.is_vegetarian !== undefined && { is_vegetarian: ar.is_vegetarian }),
                ssr_by_passengers: ar.ssr_by_passengers,
                limit_per_passenger: ar.limit_per_passenger,
              }));
          }),
        };
      }),
    };
  });
  console.log("New result ADDED SSR PASS", remappedResult);
  return { remappedResult, simCard, tripSSR };
};

export const goToCheckIn = async (req: APIGetOnlineCheckInRequestBody, token: string) => {
  const result = await fetchAPI(
    `${olciEndPonits.checkIn}/${req.journey_key}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...requiredHeader(),
        nsk_token: token,
      },
      body: JSON.stringify({
        sales_reference: req.sales_reference,
        first_name: req.first_name,
        last_name: req.last_name,
        passengers: req.passengers,
        template_language: req.template_language,
      }),
    },
    true
  );
  return result;
};

export const goToCheckPassengers = async (req: any, token: string) => {
  const result = await fetchAPI(olciEndPonits.checkPassengers, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    body: JSON.stringify(req),
  });
  return result;
};

export const changeOnlineCheckInValidation = async (req: any, token: string) => {
  const result = await fetchAPI(olciEndPonits.orderValidation, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: token,
    },
    cache: "no-store",
    errorCodeWhitelist: ErrorCodeList,
    body: JSON.stringify(req),
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    errorDetail && store.dispatch(storeErrorModalDetail(errorDetail));
  });
  return result;
};

export const createOrder = async (data: APICreateOrderRequestBody) => {
  const result = await fetchAPI(olciEndPonits.order, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      nsk_token: store.getState().session.nskToken.nsk_token,
    },
    errorCodeWhitelist: ErrorCodeList,
    body: JSON.stringify(data),
    captcha: window.hCaptchaConfig?.isEnable,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error.error_code);
    errorDetail && store.dispatch(storeErrorModalDetail(errorDetail));
  });
  return result;
};
